import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
function App() {
  const [mac, setmac] = useState("");
  const [error, setError] = useState(false);
  const [information, setInformation] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [details, setDetails] = useState({
    username: "",
    password: "",
    API_URL: "",
    useProxy: false,
    proxyip: "",
    proxyport: "",
    portuser: "",
    proxypassword: "",
    terms:false
  });
  window.addEventListener("keypress", (rr) => {
    showResponseStatus(true, "");
  });
  window.addEventListener("mouseup", (vv) => {
    showResponseStatus(true, "");
  });

  useEffect(() => {
    (async () => {
      try {
        let uuid_mac = uuidv4();
        let mac = await OfficeRuntime.storage.getItem("mac");
        if (mac) {
          setmac(mac);
        } else {
          setmac(uuid_mac);
          await OfficeRuntime.storage.setItem("mac", uuid_mac);
        }
      } catch (err) {
        setmac(uuid_mac);
        await OfficeRuntime.storage.setItem("mac", uuid_mac);
      }
    })();
    const alreadySearched = JSON.parse(localStorage.getItem("lemonConfig"));
    if (alreadySearched) {
      setDetails(alreadySearched);
    }

    getExpireOfToken();
    return () => clearInterval(interval);
  }, []);

  const showResponseStatus = async (errorStatus, message) => {
    setError(errorStatus);
    setInformation(message);
  };
  const testConnection = async () => {
    const data = new URLSearchParams();
    data.append("username", details.username);
    data.append("password", details.password);
    data.append("mac", mac);

    const url = `${details.API_URL}/auth/token`;
    if (!details.username || !details.password) {
      setIsDisable(false);
      showResponseStatus(true, "Fields Can not be empty");
    } else if (!url.includes("http://") && !url.includes("https://")) {
      setIsDisable(false);
      showResponseStatus(
        true,
        "An invalid request URI was provided. The request URI must either be an absolute URI or BaseAddress must be set."
      );
    } else if(details.terms != true){
      setIsDisable(false);
      showResponseStatus(true, "Please accept Terms and conditions");
    } else {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((response) => {
          response.json().then(async (result) => {
            if (response.status == 403) {
              showResponseStatus(
                true,
                'An email has been sent to you. Please click on "Activate" in that email to log in.'
              );
            } else if (response.status == 200) {
              setIsLogin(false);
              setIsDisable(true);
              showResponseStatus(false, "Login Successful");
              localStorage.setItem("lemonConfig", JSON.stringify(details));
              OfficeRuntime.storage.setItem("accessToken", result.access_token).then((result) => {
                console.log("Token Saved sucessfully");
              });
              OfficeRuntime.storage.setItem("lemonConfig", JSON.stringify(details)).then((result) => {
                console.log("User Details Saved sucessfully");
              });
            } else {
              showResponseStatus(true, result.detail);
            }
          });
        })
        .catch((err) => {
          showResponseStatus(true, "Test fails");
        });
    }
  };

  async function removeDataFromStorage() {
    const newDetails = { ...details, password: "",terms:false };
    setDetails(newDetails);
    localStorage.setItem("lemonConfig", JSON.stringify(newDetails));
    OfficeRuntime.storage.setItem("lemonConfig", JSON.stringify(newDetails)).then((result) => {
      console.log("User Details Saved sucessfully");
    });
    OfficeRuntime.storage.removeItem("accessToken").then((res) => {
      console.log("removed token");
    });
    let allKeys = await OfficeRuntime.storage.getKeys();
    for (let i = 0; i < allKeys.length; i++) {
      if (allKeys[i].startsWith("lemonbatch") || allKeys[i].startsWith("lemontable")) {
        OfficeRuntime.storage.removeItem(allKeys[i]).then((res) => {
          console.log("lemonbatch data removed from storage");
        });
      }
    }
  }

  async function getExpireOfToken() {
    try {  
      const currentTime = Date.now() / 1000;
      let accessToken = await getAccessToken();
      let decoded_access_token;
      if (accessToken == null) {
        setIsLogin(true);
        setIsDisable(false);
      } else {
        decoded_access_token = jwt_decode(accessToken);
        if (currentTime > decoded_access_token.exp) {
          setIsLogin(true);
          setIsDisable(false);  
          // removeDataFromStorage();
          document.getElementById("loginButton").click();
          console.log("Token renew successfully "+new Date());
        } else {
          setIsLogin(false);
          setIsDisable(true);
        }
      }

      setTimeout(() => {
        getExpireOfToken();
      }, 1000);
    } catch (err) {
      console.log("err", err);
    }
  }

  async function getAccessToken() {
    let accessToken = await OfficeRuntime.storage.getItem("accessToken");
    if (accessToken != null || accessToken != undefined) {
      return accessToken;
    }
    return null;
  }

  const handleLogout = async () => {
    setIsDisable(false);
    let accessToken = await getAccessToken();
    setIsLogin(true);
    const url = `${details.API_URL}/auth/logout`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        console.log("logout", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
    setInformation("Logout Successful");
    setTimeout(function () {
      setInformation("");
    }, 3000);
    removeDataFromStorage();
  };

  return (
    <div>
      <div className="main-div">
        <div className="inner-div" style={{ marginBottom: "40px" }}>
          Access to the SFJLemon add-in requires the prior purchase of a LEMON license. If you do not already have access credentials and wish to use the add-in, please contact info@sfjtechnologies.com.
        </div>
        <div className="inner-div">
          <input
            aria-label="username"
            name="username"
            type="input"
            onChange={(e) => setDetails({ ...details, username: e.target.value })}
            value={details.username}
            required
            disabled={isDisable}
          />
          <label className={isDisable ? "label_transitions" : ""}>Username</label>
          <div className="underline"></div>
        </div>
        <div className="inner-div">
          <input
            aria-label="password"
            name="password"
            type="password"
            onChange={(e) => setDetails({ ...details, password: e.target.value })}
            value={details.password}
            required
            disabled={isDisable}
          />
          <label className={isDisable ? "label_transitions" : ""}>Password</label>
          <div className="underline"></div>
        </div>

        <div className="inner-div">
          <input
            aria-label="API"
            name="API"
            type="input"
            onChange={(e) => setDetails({ ...details, API_URL: e.target.value })}
            value={details.API_URL}
            required
            disabled={isDisable}
          />
          <label className={isDisable ? "label_transitions" : ""}>API base url</label>
          <div className="underline"></div>
        </div>

        <div className="inner-div" style={{ marginTop: "5%", marginBottom: "-3%" }}>
          <label className="proxy-lable">useProxy</label>
          <label className="switch" style={{ pointerEvents: "all" }}>
            <div className="switch-inner-div">
              <input
                aria-label="switch-input"
                type="checkbox"
                onChange={(e) => setDetails({ ...details, useProxy: e.target.checked })}
                className="switch-input"
                required
                checked={details.useProxy}
              />
              <span className="switch-label"></span>
              <span className="switch-handle"></span>
            </div>
          </label>
        </div>

        <div className="inner-div">
          <input
            aria-label="proxyip"
            name="proxyip"
            type="input"
            disabled={!details.useProxy}
            onChange={(e) => setDetails({ ...details, proxyip: e.target.value })}
            required
          />
          <label>Proxy IP</label>
          <div className="underline"></div>
        </div>

        <div className="inner-div">
          <input
            aria-label="poxyport"
            name="poxyport"
            type="input"
            disabled={!details.useProxy}
            onChange={(e) => setDetails({ ...details, proxyport: e.target.value })}
            required
          />
          <label>Proxy Port</label>
          <div className="underline"></div>
        </div>

        <div className="inner-div">
          <input
            aria-label="portuser"
            name="portuser"
            type="input"
            disabled={!details.useProxy}
            onChange={(e) => setDetails({ ...details, proxyport: e.target.value })}
            required
          />
          <label>Port User</label>
          <div className="underline"></div>
        </div>

        <div className="inner-div">
          <input
            aria-label="proxypassword"
            name="proxypassword"
            type="input"
            disabled={!details.useProxy}
            onChange={(e) => setDetails({ ...details, proxypassword: e.target.value })}
            required
          />
          <label>Proxy Password</label>
          <div className="underline"></div>
        </div>
        <div className="inner-div">
          <table border="0">
            <tr>
              <td>
                  <div>
                      <input
                        type="checkbox"
                        onChange={(e) => setDetails({ ...details, terms: e.target.checked })}
                        style={{
                          marginTop:"auto",
                          marginLeft:"-23%",
                          transform: "scale(1.7)",
                          accentColor:"green"
                          }}
                        required
                        checked={details.terms}
                        title="I agree"
                        disabled={isDisable}
                      />
                    </div>
                </td>
                <td> I agree to the provider's <a  target="_blank"   style={{
                        }}href="https://lemonaddin.sfjtechnologies.com/assets/excel_add-in.pdf">
                          terms of use 
                      </a> and <a  target="_blank"   style={{
                        }}href="https://lemonaddin.sfjtechnologies.com/assets/excel_add-inPS.pdf">
                          privacy policy
                      </a>
                </td>
            </tr>
          </table>
        </div>
       
        <div className="testButton">
          {isLogin ? (
            <button id="loginButton" type="submit" onClick={() => testConnection()}>
              Login
            </button>
          ) : (
            <button type="submit" style={{ background: "#c10d0d" }} onClick={() => handleLogout()}>
              Logout
            </button>
          )}
        </div>
        <div className="informationSection">
          <span className="displayMessage" style={{ color: error ? "red" : "green" }}>
            {information}
          </span>
        </div>
      </div>
    </div>
  );
}
export default App;
